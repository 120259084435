import { gql, useLazyQuery } from '@apollo/client';

const AUTO_PLAN_ADDITIONAL_BENEFITS = gql`
  query autoPlanAdditionalBenefits(
  $planId: Int!
  $quote: QuoteAutoInput
  $vehicle: VehicleDetailInput
  $insured: InsuredDetailInput
) {
  autoPlanAdditionalBenefits(
    input: {
      planId: $planId
      quote: $quote
      vehicle: $vehicle
      insured: $insured
    }
  ) {
    id
    name
    autoPremiumRateId
    isOptional
    code
    price
    categoryPrice
    tsi
    upValue
    rate
    quantity
  }
}`;

export const autoPlanAdditionalBenefitsQuery = () => {
  return useLazyQuery(AUTO_PLAN_ADDITIONAL_BENEFITS);
};