
import { gql, useLazyQuery } from '@apollo/client';

const CALCULATE_PREMIUM_QUERY = gql`
    query calculatePremium(
      $plan: AutoPlanInput
      $quote: QuoteAutoInput
    ) {
      calculatePremium(
        autoCategory: {
          plan: $plan
          quote: $quote
        }
      ) {
        totalPremium
        discount
        basePremium
        extraCharges {
          totalCharges
          charges {id productId name value}
        }
        totalPremiumAfterDiscount
        discountRate
        terms {
          name
          premium
          rate
          additionalBenefits{
            id
            name
            autoPremiumRateId
            isOptional
            code
            price
            categoryPrice
            tsi
            upValue
            rate
            quantity
          }
          totalPremium
          totalPremiumAfterDiscount
        }
      }
    }
  `;

const CALCULATE_AUTO_PREMIUM_IGN_QUERY = gql`
    query calculateAutoPremiumIgn(
      $autoPlan: AutoPlanInput
      $quote: QuoteAutoInput
      $vehicle: VehicleDetailInput
      $insured: InsuredDetailInput
    ) {
      calculateAutoPremiumIgn(
        input: {
          autoPlan: $autoPlan
          quote: $quote
          vehicle: $vehicle
          insured: $insured
        }
      ) {
        totalPremium
        discount
        basePremium
        extraCharges {
          totalCharges
          charges {id productId name value}
        }
        totalPremiumAfterDiscount
        discountRate
        terms {
          name
          premium
          rate
          additionalBenefits{
            id
            name
            autoPremiumRateId
            isOptional
            code
            price
            categoryPrice
            tsi
            upValue
            rate
            quantity
          }
          totalPremium
          totalPremiumAfterDiscount
        }
      }
    }
  `;

export const getCalculatePremium = () => {
  return useLazyQuery(CALCULATE_PREMIUM_QUERY);
};

export const calculateAutoPremiumIgnQuery = () => {
  return useLazyQuery(CALCULATE_AUTO_PREMIUM_IGN_QUERY);
};