import { gql, useLazyQuery } from "@apollo/client";

const SF_OPEN_PAYMENT_LINK_QUERY = gql`
  query SFOpenPaymentLink(
    $opportunityName: String
    $hash: String
  ) {
    openPaymentLinkSf(input: {
      opportunityName: $opportunityName
      hash: $hash
    }) {
      msg
      opportunityName
      opportunity {
        opportunityId
        success
        errorCode
        sfAccountId
        opportunityNumber
        productType
        paymentFrequency
        renewalNumber
        quote {
          charges {
            value
            name
          }
          discount
          voucherCode
          productBrandLogo
          productName
          vehicleData {
            make
            modelFamily
            vehicleType
          }
          insuredData {
            firstName
            lastName
            phoneNumber
            gender
            dob
          }
          policyHolderData {
            firstName
            lastName
            phoneNumber
            gender
            dob
          }
          paymentData {
          virtualAccountData
          paymentMethod
          paymentFrequency
          paymentDecision
          agentId
          paidAmount
          isPmntInitiatedBySf
          paymentDetailsData {
            installmentNumber
            voucherCode
            voucherDiscount
            amount
            dueDate
            paymentDate
            chargeId
            paymentId
          }
        }
          quoteLineItemsData {
            policyVat
            policyStampDuty
            policyNetPremium
            policyGrossPremium
            otherDiscount
            coverages {
              additionalCoverageSumAssured
              additionalCoverageName
              additionalCoverageRate
              additionalCoveragePremium
              additionalCoverageIndex
              additionalCoverageCode
            }
          }
        }
      }
    }
  }
`;

export const salesforceOpenPaymentLinkQuery = () => {
  return useLazyQuery(SF_OPEN_PAYMENT_LINK_QUERY);
}
