import { gql, useMutation } from "@apollo/client";

const SF_GENERATE_PAYMENT_LINK = gql`
  mutation GeneratePayementLinkSf(
    $opportunityName: String
  ) {
    generatePaymentLinkSf(input: {
      opportunityName: $opportunityName
    }) {
      hash
    }
  }
`;

export const salesforceGeneratePaymentLinkMutation = () => {
  return useMutation(SF_GENERATE_PAYMENT_LINK);
}
