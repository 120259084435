import React, { useEffect, useState } from "react";
import { withRouter } from "next/router";
import getConfig from 'next/config';
import classNames from "classnames";
import PropType from "prop-types";
import Tooltip from "rc-tooltip";
import AgentCommission from "../../../../../../client/modules/agents/components/AgentCommission";
import { idr } from "../../../../../../shared/currency";
import {
  getProductUSP,
  getVoucherPercentage,
  pricingText,
  getDiscountPercentage,
  getPremiumBysupportedPaymentTerm,
} from "clientUtils";
import { AGENT_CONSTANTS } from "client/modules/agents/constants/agent.constants";
import BenefitGroup from './BenefitGroup';
import "rc-tooltip/assets/bootstrap_white.css";
import "./Main.sass";
import { MainDetail } from "./MainDetail";
import { useMainGroupContext } from "./MainGroup";
import { sendDataLayerTracker } from "../../../../../../lib/tracker";
import { CHECK_CIRCLE_SOLID_ICON, OUTLINE_CHECK_ICON, OUTLINE_CHECK_ACTIVE_ICON } from "../../../../../../shared/constants";
import { calculateAutoPremiumIgnQuery } from '../../../../../../services/salesforce';
import jsCookie from "js-cookie";
import { format } from "date-fns";
import { Products } from '../../../../../../lib/api';

const { SOMPO_BY_ROOJAI_BRAND_ID } = getConfig().publicRuntimeConfig;

const MainItem = (props) => {
  const {
    token,
    item,
    onBuyClick,
    onCompare,
    onDetailClick,
    onAutoFocusAgeClick,
    toggleModal,
    canCompare,
    isAutoMoto,
    isModal,
    callMeHandler,
    router: { query },
    agentCommissionPercentage,
    brandsConfig,
  } = props;
  const { activeId, getUniqueId, setActiveId } = useMainGroupContext();
  const [show, setShow] = useState(false);
  const [uniqueId, setUniqueId] = useState();
  const [calculatePremiumIgn] = calculateAutoPremiumIgnQuery();
  const [totalPremium, setTotalPremium] = useState(0);
  const productService = new Products(token)

  useEffect(() => {
    getUniqueId && setUniqueId(getUniqueId());
  }, [getUniqueId]);

  useEffect(() => {
    if (activeId && activeId !== uniqueId && show) {
      setShow(false);
    }
  }, [activeId]);

  const fetchCalculatePremiumIgn = async () => {
    const response = await productService.getYears(query.vehicleTypeId)

    if (!response) {
      return;
    }

    const yearData = response.find((item) => item.year == query.year);
    const qualificationAnswers = JSON.parse(jsCookie.get("QUALIFICATION_FORM") ?? "{}").answers;

    if (!(qualificationAnswers && Object.keys(query).length > 0)) {
      return;
    }

    try {
      const { data, error } = await calculatePremiumIgn({
        variables: {
          autoPlan: {
            "planId": item.id,
            "termYear": 1,
            "paymentTerm": "ONE_TIME",
            "discount": 0,
            "autoAdditionalBenefits": []
          },
          quote: {
            "vehicleTypeId": parseInt(query.vehicleTypeId ?? "0"),
            "vehicleYear": parseInt(query.year ?? "2000"),
            "tsi": yearData.upper_price,
            "region": query.region,
          },
          vehicle: {
            "carPlateCode": query.carPlateProvince,
            "odometer": parseInt(qualificationAnswers[1] ?? "0"),
            "hasDashCam": (qualificationAnswers[0] ?? "") == "Yes" ? true : false,
            "ownershipType": (qualificationAnswers[3] ?? "") == "Yes" ? "Company" : "Private",
            "usageType": qualificationAnswers[2] ?? "",
          },
          insured: {
            "driverAccidents": qualificationAnswers[4] ?? "",
            "gender": qualificationAnswers[5] ?? "",
            "maritalStatus": qualificationAnswers[6] ?? "",
            "dob": qualificationAnswers[7] ? format(new Date(qualificationAnswers[7]), 'yyyy-MM-dd') : "",
            "drivingExperience": qualificationAnswers[8] ?? "",
            "occupation": "",
          }
        },
      })

      if (error) {
        console.log("Error response calculatePremiumIgn: ", error);
        return;
      }

      if (data) {
        setTotalPremium(data.calculateAutoPremiumIgn.totalPremium)
      }
    } catch (error) {
      console.log("Error fetch calculatePremiumIgn: ", error);
      return;
    }
  }

  useEffect(() => {
    if (SOMPO_BY_ROOJAI_BRAND_ID == item.product.brand.id) {
      fetchCalculatePremiumIgn()
    }
  }, [item]);

  const toggleOpen = () => {
    if (!show) {
      sendDataLayerTracker({
        event: 'plan_listing_engagement',
        eventCategory: 'plan-listing-engagement',
        eventAction: 'see-policy',
        eventLabel: query.category,
        insuranceBrand: item?.product?.brand.name,
      });
    }
    setActiveId && setActiveId(show ? undefined : uniqueId);
    setShow(!show);
  }

  // Check age
  let isAgeRequired = false;
  const coverageKeys = Object.keys(item.product.attributes);
  const coverages = [];


  if (item.shouldFilterByAge && !query.age) isAgeRequired = true;

  coverageKeys.forEach((key) => {
    const attributeValue = item.product.attributes[key];

    if (attributeValue === "No") return;

    if (attributeValue === "Yes") {
      coverages.push(key);
      return;
    }

    if (attributeValue === "Optional") {
      coverages.push(`${key} (Opsional)`);
      return;
    }

    if (key !== "Cicilan") {
      coverages.push(`${key} ${attributeValue}`);
      return;
    }
  });

  coverages.sort((a, b) => {
    if (a.includes("Opsional") && !b.includes("Opsional")) {
      return 1;
    } else if (!a.includes("Opsional") && b.includes("Opsional")) {
      return -1;
    } else {
      return 0;
    }
  });

  // Check if the premi  can buy
  let isPremiCanBuy = true;
  let cantBuyLabel = "";

  const premiumData = getPremiumBysupportedPaymentTerm(item, true);
  const discount = getDiscountPercentage(brandsConfig, item.product.brand.id)?.percentage;
  const voucher = getVoucherPercentage(brandsConfig, item.product.brand.id);
  const voucherPercentage = (voucher?.percentage ?? 0) / 100;
  const voucherAmount = item.before_price < premiumData.amount && item.before_price > 0
    ? item.before_price * voucherPercentage
    : premiumData.amount * voucherPercentage;
  const productUSP = getProductUSP(brandsConfig, item.product.brand.id, item.product.id);

  if (totalPremium == 0 && SOMPO_BY_ROOJAI_BRAND_ID == item.product.brand.id) {
    return null
  }

  return (
    <div className={classNames("MainItem", !isModal && "MainItem--withBorder")}>
      <header className="MainItem--Header">
        <div className="MainItem--HeaderInfo">
          <div className="product__header--info">
            <div className="product__logo--wrapper">
              <a target="_blank"
                href={item.product.brand.brand_page_url != '' && item.product.brand.brand_page_url}>
                <img src={item.product.brand.logo_url} alt="" />
              </a>
            </div>
          </div>
          <div className="product__header--info plan__premium--details">
            <span>{item.product.name} {!isAutoMoto ? `- ${item.name}` : ""}</span>
          </div>
          <div className="product__header--info plan__premium--details">
            <div className="heading-sm-b plan__premium--amount">
              <div className="plan__premium--inline">
                {item.before_price > 0 &&
                  premiumData.amount > 0 &&
                  item.before_price !== premiumData.amount && (
                    <span className="before__premium">
                      <span className="strike__through">
                        {idr(
                          item.before_price > premiumData.amount
                            ? item.before_price
                            : premiumData.amount
                        )}{" "}
                        /{item.product.support_monthly ? "Bulan" : "Tahun"}
                      </span>
                      <Tooltip
                        placement="top"
                        overlay="Rata-rata harga premi dipasaran"
                        arrowContent={
                          <div
                            className="rc-tooltip-arrow-inner"
                            style={{ color: "rgba(0, 0, 0, 0.8)" }}
                          />
                        }
                      >
                        <i
                          className="icon ion-md-information-circle ml-2 clickable"
                          style={{ color: "#000" }}
                        />
                      </Tooltip>
                    </span>
                  )}
              </div>
              <div className="plan__payment--terms">
                <div className="plan__premium--price">
                  <span style={{ marginBottom: "2px" }}>Rp</span>
                  <span className="plan__premium--price-number">
                    {SOMPO_BY_ROOJAI_BRAND_ID == item.product.brand.id ?
                      (
                        <>
                          {idr(totalPremium).replace('Rp', '')}
                        </>
                      ) : (
                        <>
                          {
                            pricingText({
                              isAutoMoto: isAutoMoto,
                              item: item,
                              premiumData: premiumData,
                              parseToIdr: idr,
                              discount: discount,
                            }).replace('Rp', '')
                          }
                        </>)
                    }
                  </span>
                  <span>{" / "}</span>
                  <span className="plan__premium--terms">{premiumData.paymentTerm.term}</span>
                  {isAutoMoto && (
                    <span className="plan__premium--amount_discount">
                      &nbsp;
                      {`Diskon ${discount ?? "25"}%`}
                      &nbsp;
                    </span>
                  )}
                </div>
                {isAutoMoto && (
                  <p className="plan__copy--call-me">
                    Dapatkan voucher extra&nbsp;
                    {
                      voucher && (
                        <span>s/d <span className="plan__copy--voucher-amount">{idr(voucher.amount ?? voucherAmount)}</span></span>
                      )
                    }
                    {!voucher ? ',' : ''} klik&nbsp;
                    <button
                      onClick={() => callMeHandler("callme", "PlanCardLink", item)}
                    >
                      Hubungi Saya!
                    </button>
                  </p>
                )}
              </div>

              <AgentCommission
                plan={item}
                role={AGENT_CONSTANTS.getAgentByToken(token)}
                totalPremium={premiumData.amount}
                paymentTerm={premiumData.paymentTerm}
                agentCommissionPercentage={agentCommissionPercentage}
                isPlanListing
              />
            </div>
          </div>
          {
            productUSP && (
              <div className="proudct__usp--wrapper">
                {
                  productUSP.map((usp, index) => (
                    <div
                      className="product__usp--item"
                      key={`product-usp-${index}`}>
                      <img src={CHECK_CIRCLE_SOLID_ICON} alt="" />
                      <span>{usp}</span>
                    </div>
                  ))
                }
              </div>
            )
          }
        </div>
      </header>

      {(item.death_benefits > 0 ||
        (item.benefit_groups &&
          item.benefit_groups.length > 0 &&
          item.death_benefits > 0 &&
          item.death_benefits > 0)) && (
          <div className="MainItem--Info">
            {item.death_benefits > 0 && (
              <div className="MainItem--InfoItem">
                <div className="MainItem--InfoTitle heading-xs-r">
                  <i
                    className="lifepal-check_round"
                    style={{ color: "#4bd0a9" }}
                  />
                  Tunjangan Kematian
                </div>
                <div className="MainItem--InfoValue heading-xs-m">
                  {idr(item.death_benefits)}
                </div>
              </div>
            )}
            {item.benefit_groups.length > 0 &&
              <BenefitGroup benefitGroups={item.benefit_groups} />
            }
          </div>
        )}

      <div className="MainItem--Action__Container">
        <button
          type="button"
          className={classNames("button_expand", show && "active")}
          onClick={toggleOpen}
        >
          <span>{show ? 'Sembunyikan' : 'Lihat Manfaat Polis'}</span>
          <i
            className={`${!show ? "lifepal-arrow-down" : "lifepal-arrow-up"}`}
          />
        </button>
        <button
          onClick={onCompare}
          disabled={!canCompare && !item.isSelected}
          className={item.isSelected && "MainItem--Action_compare_active"}
        >
          <img src={item.isSelected ? OUTLINE_CHECK_ACTIVE_ICON : OUTLINE_CHECK_ICON} alt="" />
          <span>Bandingkan</span>
        </button>
      </div>

      <MainDetail
        display={show}
        category={query.category}
        coverages={coverages}
        item={item}
        onConsultCliked={toggleModal}
        onDetailClick={onDetailClick}
        onCallmeClicked={() => {
          callMeHandler("callme", "PlanCard", item, false)
          sendDataLayerTracker({
            event: 'contact_me',
            eventCategory: 'schedule-consultation',
            eventAction: 'plan-card',
            eventLabel: query.category,
            insuranceBrand: item?.product?.brand.name,
            scheduledDay: undefined,
            scheduledTime: undefined
          })
        }}
        onBuyClick={
          isAgeRequired
            ? () => {
              window.scrollTo(0, 0);
              onAutoFocusAgeClick();
            }
            : onBuyClick
        }
        isAgeRequired={isAgeRequired}
        classNames={{
          buyButton: !isModal && "MainItem--Action_thirdbtn",
        }}
      />
    </div>
  );
};

MainItem.propTypes = {
  onBuyClick: PropType.func,
  onDetailClick: PropType.func,
  onCompare: PropType.func,
  canCompare: PropType.bool,
  selected: PropType.number,
  item: PropType.shape({
    isSelected: PropType.bool,
    shouldFilterByAge: PropType.bool,
    logo: PropType.string,
    name: PropType.string,
    plan: PropType.string,
    info: PropType.shape({
      premium: PropType.shape({
        dynamic: PropType.bool,
        price: PropType.number,
        type: PropType.string,
      }),
    }),
    promo: PropType.shape({
      title: PropType.string,
      color: PropType.string,
    }),
    description: PropType.string,
    coverages: PropType.arrayOf(PropType.string),
  }),
};

export default withRouter(MainItem);
