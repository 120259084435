import { gql, useMutation } from "@apollo/client";

const SAVE_PAYLOAD = gql`
  mutation SavePayloadPayment(
    $requestId: String!
    $opportunityId: String!
    $paymentMethod: String!
    $externalId: String
    $request: String!
    $response: String!
  ) {
    savePayload(
      input: {
        requestId: $requestId
        opportunityId: $opportunityId
        paymentMethod: $paymentMethod
        externalId: $externalId
        request: $request
        response: $response
      }
    ) {
      requestId
      opportunityId
    }
  }
`;

export const salesforcePaymentSavePayloadMutation = () => {
  return useMutation(SAVE_PAYLOAD);
}
